<template>
  <static-fullscreen-card @sendHeight="setContentHeight">
    <template v-slot:title>
      <a-btn-unblock
        v-if="getAccess('documents.orderService.status', { users: [data.createdby_id] })"
        :id="data.id"
        @unblock="fitchData()"
        :api="api"
        :blocked="data.blocked"
      />
      ({{ data.id }}) {{ m.title + ` №${data.code_doc}` }} [{{ statusCurrent.text || "" }}]
    </template>
    <template v-slot:actions>
      <v-tooltip bottom v-if="hintStatus">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">fas fa-info-circle</v-icon>
        </template>
        <span v-html="hintStatus"></span>
      </v-tooltip>
      <a-btn-status
        v-if="!showOnly && data.data_table && getAccess('documents.orderService.status', { users: [data.createdby_id] })"
        :items="statusItems"
        @click="changeStatus($event)"
        :disabled1="data.data_table.length == 0 || loading"
        :disabled="!canStatus"
      />
      <a-btn-edit
        v-if="
          !showOnly &&
          data.status !== 2 &&
          getAccess('documents.orderService.edit', {
            users: [data.createdby_id],
          })
        "
        @click="showEditDialog = true"
        :disabled="loading || data.data_table.length !== 0"
      />
      <a-btn-delete
        v-if="
          !showOnly &&
          id &&
          data.status !== 2 &&
          data.data_table &&
          !data.data_table.length &&
          getAccess('documents.orderService.delete', {
            users: [data.createdby_id],
          })
        "
        @click="removeDialogShow = true"
      />
    </template>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <v-row>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-loader v-if="loading" />
          <a-form-view v-else :value="data" :model="modelLeft" :config="{ dense: true }" @click="onClick($event)" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view
            :value="balance"
            :model="[
              { name: 'credit', title: 'Итого к оплате', type: 'number' },
              { name: 'debit', title: 'Оплачено', type: 'number' },
              {
                name: 'saldo',
                value: balance.credit - balance.debit,
                title: 'Долг',
                type: 'number',
              },
            ]"
            :config="{ dense: true }"
            @click="getBalance()"
          />
        </material-card>
      </v-col>
      <v-col cols="12" sm="12" class="px-2">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(t, i) in tabs" v-if="t.show" :href="`#tab-${t.name}`" :key="i" class="mr-3">
                {{ t.title }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item v-for="(t, i) in tabs" :key="i" v-if="t.show" :id="`tab-${t.name}`">
              <v-card-text v-if="t.name == 'detail'" :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <a-table-f-data2
                  ref="tableDetail"
                  :dataTable="data.data_table"
                  :model="modelDetail"
                  :searchable="false"
                  :defaults="{
                    sort: { key: 'id', order: 'DESC' },
                    paramName: 'documentOrderServiceDetail',
                  }"
                  @click="onClickRow($event)"
                >
                  <template v-slot:item.actions="{ row }">
                    <div
                      class="d-flex"
                      v-if="
                        data.status == 1 &&
                        getAccess('documents.orderService.edit', {
                          users: [data.createdby_id],
                        })
                      "
                    >
                      <v-btn x-small fab class="ma-0" color="red" title="Удалить" @click.stop="delRow(row.id)">
                        <v-icon small>fas fa-times</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <template v-slot:top>
                    <v-fab-transition
                      v-if="
                        data.status == 1 &&
                        getAccess('documents.orderService.edit', {
                          users: [data.createdby_id],
                        })
                      "
                    >
                      <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="addRecord">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </template>
                </a-table-f-data2>
              </v-card-text>
              <s-tabs-pay-order
                ref="tabPayOrder"
                v-if="t.name == 'payOrder'"
                :parent="m.accDocName"
                :balance="balance"
                :data="data"
                :canAdd="getAccess('payOrder.create')"
                :m="m"
                @add="createPayOrder($event)"
                :height="detailHeight"
              />
              <s-tabs-cash
                v-if="t.name == 'cash'"
                :data="data"
                :operations="m.operationsCash[data.type] || []"
                type="out"
                :canAdd="
                  data.status == 2 &&
                  getAccess('documents.orderService.edit', {
                    users: [data.createdby_id],
                  }) &&
                  checkPayOrder()
                "
                :m="m"
                :height="detailHeight"
              />

              <comment-view v-if="t.name == 'comments'" :id="id || 0" :targetName="'orderService'" :needUpdate="needUpdateComments" />
              <v-card-text v-if="t.name == 'allFiles'" :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <a-view-files2 v-if="tab == 'tab-allFiles'" :id="data.id" :name="m.accDocName" />
              </v-card-text>
              <v-card-text v-if="t.name == 'account'" :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <s-document-acc-record :id="id" :name="accDocName" :join="{ AccountingDocOrderWithdrawalModel: { parent_name: m.accDocName, parent_id: id } }" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <portal to="v-main">
      <s-edit-form
        ref="payOrder"
        :opt="{ newWindow: true }"
        v-if="showPayOrderDialog"
        v-model="showPayOrderDialog"
        :configForm="{ type: 'create' }"
        :m="$store.getters['config/get'].models.payOrder"
        :initData="payOrderData"
      />
      <edit-dialog v-if="showEditDialog" v-model="showEditDialog" :id="id" :api="url" :m="m" :initType="data.type"></edit-dialog>
      <edit-Service
        v-if="showDialogRowEdit"
        v-model="showDialogRowEdit"
        :id="idEdit"
        :dataTable="data.data_table"
        :RO="fieldsRO"
        :readonly="data.status == 2"
        @save="editRows($event)"
      />
      <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
    </portal>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
    <a-loader v-if="loading" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </static-fullscreen-card>
</template>

<script>
//import { statusTask } from "@/config/modules/libs";

import { autoHeightBlock, getAccess, popupMenu, removeEl, saveEl, genModel, comments } from "@/components/mixings";
import libsCash from "./../libsCash";

export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, removeEl, saveEl, genModel, libsCash, comments],
  components: {
    editService: () => import("./../dialogs/orderEditServiceDialog"),
    editDialog: () => import("./../dialogs/orderCreateDialog"),
  },
  props: {
    idShow: Number,
    showOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      id: 0,
      idEdit: 0,
      fieldsRO: [],
      accDocName: "AccountingDocOrderServiceModel",
      showEditDialog: false,
      showDialogRowEdit: false,
      showCashSelectDialog: false,
      showCashCreateDialog: false,
      showPayOrderDialog: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить документ?",
      loading: false,
      loaded: false,
      title: "",
      data: {},
      modelDetail: this.$store.getters["config/get"].models.documentOrderService.listDetail,
      m: this.$store.getters["config/get"].models.documentOrderService,
      statuses: this.$store.getters["config/get"].models.documentOrderService.statuses,
      url: "/accounting/doc/order_service",
      api: "/accounting/doc/order_service",
      apiStatus: "/accounting/doc/order_service",
      tab: 0,
      tabs: [
        { name: "detail", title: "Детали", show: true },
        { name: "cash", title: "Оплата", show: true },
        { name: "payOrder", title: "Заявки", show: true },
        { name: "comments", title: "Комментарии", show: this.getAccess(`documents.orderGoods.comments`, null, true) },
        { name: "allFiles", title: "Документы", show: 1 },
        { name: "account", title: "Проводки", show: this.getAccess("documents.orderGoods.accounting") },
      ],
      showDialogAddGoods: false,
      blockHeight: 256,
      debit: null,
      credit: null,
      statusCur: null,
      hintStatus: null,
    };
  },
  created() {
    console.log("create");
    this.loading = true;

    this.$root.title = "Заказ Услуг";
    this.id = this.idShow || Number(this.$route.params.id);
    this.fitchData();
    this.loaded = true;
  },
  computed: {
    permit() {
      let res =
        this.getAccess("menu.objects") &&
        this.getAccess("documents.orderService.viewAll", {
          users: [this.data.createdby_id],
        });
      console.log("permit", res);
      return res;
    },
    balance() {
      return { debit: this.debit, credit: this.credit };
    },
    model: {
      get() {
        let type = this.data.type || 1;
        let model = JSON.parse(JSON.stringify(this.m.form));
        // let model = JSON.parse(JSON.stringify(this.m["form" + type]));
        model.forEach(el => {
          if (el?.sourceList) {
            //если список вариантов находится во внешнем массиве, присоединяю его
            el.options = this.m?.[el.sourceList]
              ? this.m[el.sourceList].filter(list => {
                  return !list?.hidden;
                })
              : [];
          }
        });
        this.modelReady = true;

        return model;
      },
    },
    modelLeft() {
      let type = this?.data?.type || 0;
      let hide = "";
      let model = this.calcModel("viewForm1");
      model.forEach(el => {
        if (hide.split(",").includes(el.name)) el.type = null;
      });
      return model;
    },
    canStatus() {
      this.hintStatus = "";
      if (!this.data.vendor_id) {
        this.hintStatus += "Не заполнен поставщик<br/>";
        return false;
      }
      this.hintStatus += this.data.blocked ? "Документ заблокирован <br/>" : "";

      return !(this.data.data_table && this.data.data_table.length == 0) && !this.loading && !this.hintStatus;
    },
    statusItems() {
      if (!this.data.status) return [];
      let items = [];
      let res = [];
      const statusCur = this.statusCurrent;

      if (statusCur && statusCur.next) {
        let arr = statusCur.next;
        res = this.statuses.filter(s => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    statusCurrent() {
      let statusCur = this.statuses.find(el => el.value == this.data.status);
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
  },
  methods: {
    checkPayOrder() {
      let res = true;
      if (this.data.pay_orders_data) {
        const d = this.data.pay_orders_data;

        if (d.length && d.find(p => p.payment_type == 1 && [0, 1].includes(p.status))) res = false;
      }
      return res;
    },
    openPayOrder() {
      if (!this.data.payorder_id) return;
      const routeData = this.$router.resolve({ name: "payOrder_view", params: { id: this.data.payorder_id } });
      window.open(routeData.href, "_blank");
    },
    createPayOrder(v) {
      let data = {
        parent_name: this.m.modelName,
        parent_id: this.data.id,
        amount: v !== undefined ? v : this.balance.credit,
        type: "orderServicePayment",
        object_id: this.data.type == 1 ? this.data.object_code : null,
        name: `Требуется оплата заказа услуг №${this.data.code_doc}`,
      };
      this.payOrderData = data;
      this.showPayOrderDialog = true;
      this.$nextTick(() => {
        console.log(this.$refs.payOrder);
        //        this.$refs.payOrder.inputData(data);
      });
    },
    onClick(d) {
      if (d.field?.isMenu) {
        if (d.field.name == "object_code") {
          let name = d.row.type == 1 ? "object" : d.row.type == 2 ? "office" : "storage";
          this.showPopupMenu(name, { id: d.row.object_code }, d.event);
        } else this.showPopupMenu(d.field.name, d.row, d.event);
      }
    },
    async fitchData() {
      this.loading = true;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.afterFetchData();

      this.loading = false;
    },
    async getBalance() {
      console.log("getBalance");
      let debit = 0;
      let credit = 0;
      if (this.data.status !== 2) {
        this.data.data_table.forEach(el => {
          credit += el.price;
        });
      }
      //const acc = this.config.debit;
      let resp = await this.$axios.post("accounting/records/report-sql", {
        q: `
select id, credit_account 'account', value 'value_cr', null 'value_db'
from accounting_record
where credit_account like "60.2" and credit_subconto_value_2=${this.id}
union all
select id, debit_account 'account',	null 'value_cr', value 'value_db'
from accounting_record
where debit_account like "60.2" and debit_subconto_value_2=${this.id}
`,
      });

      if (resp.data.status == "ok") {
        resp.data.data.forEach(el => {
          debit += el.value_db;
          credit += el.value_cr;
        });
      }

      this.credit = credit;
      this.debit = debit;
    },
    onClickRow(e) {
      if (e.field?.isMenu) {
        this.showPopupMenu(e.field.name, e.row, e.event);
      } else if (e.field.name == "actions") {
      } else {
        this.idEdit = e.row.id;
        this.fieldsRO = this.data.operation_type == 1 ? ["name", "amount", "price"] : [];
        this.showDialogRowEdit = true;
      }
    },
    addRecord() {
      this.idEdit = 0;
      this.fieldsRO = [];
      this.showDialogRowEdit = true;
    },
    async addRows(r) {
      if (this.data.type == 11) {
        const api = this.url;
        const data_table = [...this.data.data_table, ...r];
        const data = { id: this.id, data_table };
        let response = await this.$axios.post(api, data);
        let status = response.data.status == "ok";
        if (status) {
          this.$root.$emit("show-info", {
            text: "Данные сохранены",
          });
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка добавления данных",
          });
        }
        this.fitchData();
      }
    },
    async editRows(r) {
      if (this.data.type == 1 || this.data.type == 2) {
        const api = this.url;
        const data_table = r;
        const data = { id: this.id, data_table };
        console.log("post", data);
        let response = await this.$axios.post(api, data);
        let status = response.data.status == "ok";
        if (status) {
          this.$root.$emit("show-info", {
            text: "Данные сохранены",
          });
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка изменения данных",
          });
        }
        this.fitchData();
      }
    },
    async delRow(id) {
      if ([1, 2].includes(this.data.type)) {
        const api = this.url;
        const data_table = this.data.data_table.filter(el => el.id !== id);
        const data = { id: this.id, data_table };
        let response = await this.$axios.post(api, data);
        let status = response.data.status == "ok";
        if (status) {
          this.$root.$emit("show-info", {
            text: "Данные удалены",
          });
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка удаления ",
          });
        }
        this.fitchData();
      }
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$router.push({ name: "ordersService" });
    },
    afterFetchData(r) {
      if (this.id) {
        this.getBalance();
      }
      this.loaded = true;
    },
    async changeStatus(status) {
      if (status.value !== 2) {
        let resp = await this.$axios.post("accounting/records/report-sql", {
          q: `select id from accounting_doc_order_withdrawal where parent_id=${this.id} and parent_name="${this.accDocName}" and deleted<>1`,
        });
        if (resp.data.status == "ok") {
          if (resp.data.data.length > 0) {
            this.$root.$emit("show-info", {
              type: "error",
              text: "Не возможно сменить статус. Есть связанный документ оплаты",
            });
            return;
          }
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Не возможно сменить статус. Произошла ошибка проверки",
          });
          return;
        }
      }
      let data = { id: this.id, status: status.value };
      let response = await this.save(this.apiStatus, data);
      if (response && response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Статус не изменен",
        });
      }
      return;
      /*
      this.loading = true;
      let data = { id: this.id, status: status.value };
      let response = await this.$axios.post(this.apiStatus, data);
      this.loading = false;
      status = response.data.status == "ok";
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      } */
    },
    afterSave(response) {},
    async onSelectOperation(e) {
      if (e.id == 62) {
        const resp = await this.$axios.get("mechti/objects/" + this.data.object_code);
        if (resp.status == 200) {
          this.data.investor_id = resp.data.data.investor_id;
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка получения данных об объекте",
          });
          return;
        }
      }
      this.showCashDialog(0, e.id);
    },
    async showCashDialog(id, type) {
      this.idEditCash = id;
      let getConfig = await this.onConfigDocOut(type);
      if (!getConfig) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Документ не настроен",
        });
        return;
      }
      this.showCashCreateDialog = true;
    },
    onClickCash(e) {
      this.showCashDialog(e.row.id, e.row.operation_type);
    },
  },
};
</script>
